.container {
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: #4848d5;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 150px;
    border-radius: 12px;
    padding: 16px;
    gap: 32px;
    background-color: #F6F6F8;
}

.successContainer {
    margin-top: 32px;
    display: flex;
}

.loader {
    position: absolute;
    display: flex;
    flex: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: 'rgba(0,0,0,0.5)';
    z-index: 5;
    justify-content: center;
    align-items: center;
}

.heading16 {
    font-family: 'Montserrat', 'sans-serif';
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.01 * 18;
    text-align: center;
    color: #FFF;
    margin: 0 !important;
}