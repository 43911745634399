.actionButton {
    margin-top: 32px;
    position: relative;
    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 10px;
    border: none;
}

.googleButton {
    color: #4848d5;
    background-color: white;
}

.googleButton::before {
    content: '';
    /* Required */
    position: absolute;
    top: -5px;
    /* Distance from the button */
    left: -5px;
    /* Distance from the button */
    right: -5px;
    /* Distance from the button */
    bottom: -5px;
    /* Distance from the button */
    border: 1px solid #FFF;
    transition: all 0.3s ease;
    /* Border color and width */
    border-radius: 10px;
    /* If the button has rounded corners */
}

.googleButton:hover {
    background-color: #4848d5;
    color: white;
    cursor: pointer;
}

.googleButton:hover::before {
    border-color: white;
    /* Change border color on hover */
    top: -8px;
    /* Increase the space */
    left: -8px;
    right: -8px;
    bottom: -8px;
}