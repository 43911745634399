.userContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.profileImage {
    width: 60px;
    height: 60px;
    border-radius: 30px;
}

.userInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.heading14 {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01 * 14px;
}

.bobasContainer {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
}