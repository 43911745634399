.container {
    /* height: 100vh; */
    /* display: flex; */
    /* background: linear-gradient(to right, #ff6e7f, #bfe9ff); */
    background: #202731;
    overflow-x: hidden;
    position: relative;
}

.scrollIndicator {
    display: none;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateX(-50%) translateY(0);
    }

    40% {
        transform: translateX(-50%) translateY(-10px);
    }

    60% {
        transform: translateX(-50%) translateY(-5px);
    }
}

section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 200px;
    padding: 100px 20vw;
    color: white;
}

.blue {
    /* background: #4848d5; */
    /* background: url("../assets/background.svg") no-repeat; */
    background: linear-gradient(to right, #4848d5, #a84df9);
}

.orange {
    background: #FF6525;
}

.purple {
    background: linear-gradient(to right, #a84df9, #4848d5);
    min-height: 500px;
    position: relative;
    /* padding-top: 300px; */
}

.headingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
}

.cardHeading {
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 2rem;
    font-weight: 700;
}

.cardText {
    color: #D9d9d9;
    font-family: "Inter", sans-serif;
    font-size: 14px;
}

.bottomCurve {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.bottomCurve svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 217px;
}

.bottomCurve .bottomFill {
    fill: #202731;
}

.contactSection {
    position: relative;

}

.contactSection::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/boba-app-cover.webp");
    background-size: cover;
    background-repeat: no-repeat;
    /* Prevent image repetition */
    background-attachment: fixed;
    overflow-y: auto;
    opacity: 0.75;
    /* z-index: -1; */
}

.dark {
    background: #202731;
    min-height: 300px;
    padding-top: 300px;
}

.curve {
    position: absolute;
    height: 225px;
    width: 100vw;
    bottom: 0;
    z-index: 10;
}

.curve::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 55%;
    height: 100%;
    background-color: #202731;
    transform: translate(85%, 60%);
}

.curve::after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100% 50%;
    width: 110%;
    height: 100%;
    /* background-color: #4848d5; */
    /* background: url("../assets/background.svg"); */
    background: linear-gradient(to right, #4848d5, #a84df9);
    transform: translate(-4%, 40%);
    z-index: -1;
}

.wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.wave svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 240px;
}

.wave .shapeFill {
    fill: #202731;
}

.headingWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

.logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    width: 20vw;
}

.slogan {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    letter-spacing: 2.3px;
    font-weight: bold;
}

.heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    text-align: center;
}

.imageWrapper {
    position: absolute;
    z-index: 15;
    top: 40%;

    display: flex;
    justify-content: center;
}

.landingImage {
    width: 70%;
}

.buttonWrapper {
    display: flex;
    flex-direction: row;
    z-index: 20;
    gap: 32px;
    padding: 20px;
}

.actionButton {
    position: relative;
    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 10px;
    border: none;
}

.appleIcon {
    margin-bottom: 4px;
}

.appleButton {
    color: white;
    background-color: #4848d5;
    /* box-shadow: 0 0 0 4px black, 0 0 0 8px white; */
}

.appleButton::before {
    content: '';
    /* Required */
    position: absolute;
    top: -5px;
    /* Distance from the button */
    left: -5px;
    /* Distance from the button */
    right: -5px;
    /* Distance from the button */
    bottom: -5px;
    /* Distance from the button */
    border: 1px solid #FFF;
    transition: all 0.3s ease;
    /* Border color and width */
    border-radius: 10px;
    /* If the button has rounded corners */
}

.appleButton:hover {
    background-color: white;
    color: #4848d5;
    cursor: pointer;
}

.appleButton:hover::before {
    border-color: white;
    /* Change border color on hover */
    top: -8px;
    /* Increase the space */
    left: -8px;
    right: -8px;
    bottom: -8px;
}

.googleButton {
    color: #4848d5;
    background-color: white;
}

.googleButton::before {
    content: '';
    /* Required */
    position: absolute;
    top: -5px;
    /* Distance from the button */
    left: -5px;
    /* Distance from the button */
    right: -5px;
    /* Distance from the button */
    bottom: -5px;
    /* Distance from the button */
    border: 1px solid #FFF;
    transition: all 0.3s ease;
    /* Border color and width */
    border-radius: 10px;
    /* If the button has rounded corners */
}

.googleButton:hover {
    background-color: #4848d5;
    color: white;
    cursor: pointer;
}

.googleButton:hover::before {
    border-color: white;
    /* Change border color on hover */
    top: -8px;
    /* Increase the space */
    left: -8px;
    right: -8px;
    bottom: -8px;
}

.featureContainer {
    display: flex;
    flex-direction: row;
    gap: 32px;
    /* flex-wrap: wrap; */
}

.contactButton {
    margin: 25px;
    padding: 10px 55px;
}

.mobileSwiper {
    display: none;
    /* width: 100%; */
}

.mobileView {
    width: 50vw;
}

.functionalitySection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 128px;
    padding: 100px 10vw;
}

.scanSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.scanSection2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.receiptImage {
    width: 16vw;
}

.bannerImage {
    width: 100vw;
}

.hidden {
    display: none;
}

@media only screen and (max-width: 400px) {
    .receiptImage {
        width: 100vw;
    }
}

@media only screen and (max-width: 600px) {
    .scrollIndicator {
        display: block;
        position: absolute;
        bottom: 20px;
        /* Adjust based on your layout */
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        animation: bounce 2s infinite;
        cursor: pointer;
        color: #4848d5;
        /* Icon/Text color */
        background-color: rgba(255, 255, 255, 0.8);
        /* Light background for contrast */
        border: 2px solid #4848d5;
        /* Border color */
        border-radius: 50%;
        /* Circular shape */
        padding: 10px;
        /* Adjust based on the size of the icon */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        /* Soft shadow for depth */
        z-index: 100;
    }

    section {
        padding: 100px 10vw;
        /* height: 450px; */
    }

    .logo {
        width: 60vw;
    }

    .buttonWrapper {
        padding-top: 10vh;
        flex-direction: column;
    }

    .imageWrapper {
        top: 70%
    }

    .landingImage {
        width: 150%;
    }

    .purple {
        padding-top: 200px;
    }

    .functionalitySection {
        gap: 64px;
    }

    .scanSection {
        flex-direction: column-reverse;
        gap: 24px;
    }

    .scanSection2 {
        flex-direction: column;
        gap: 24px;
    }

    .receiptImage {
        width: 30vw;
    }
}


@media only screen and (max-width: 1200px) {
    .imageWrapper {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 1250px) {
    .imageWrapper {
        top: 42.5%;
    }
}

@media only screen and (max-width: 1000px) {
    .imageWrapper {
        top: 45%;
    }
}

@media only screen and (max-width: 900px) {
    .imageWrapper {
        top: 50%;
    }
}

@media only screen and (max-width: 800px) {
    .imageWrapper {
        top: 60%;
    }
}

@media only screen and (max-width: 650px) {
    .landingImage {
        width: 100vw;
    }

    .imageWrapper {
        top: 60%;
    }
}

@media only screen and (max-width: 600px) {
    .landingImage {
        width: 100vw;
    }

    .imageWrapper {
        top: 70%;
    }
}

@media only screen and (max-width: 500px) {
    .landingImage {
        width: 120vw;
    }
}

@media only screen and (max-width: 400px) {
    .landingImage {
        width: 140vw;
    }
}

@media only screen and (max-width: 445px) {
    .landingImage {
        width: 150vw;
    }
}

@media only screen and (max-width: 350px) {
    .landingImage {
        width: 160vw;
    }

    .imageWrapper {
        top: 75%;
    }
}

@media only screen and (max-width: 300px) {
    .landingImage {
        width: 165vw;
    }

    .imageWrapper {
        top: 75%;
    }
}

@media only screen and (max-width: 1300px) {
    .featureContainer {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 1025px) {
    .dark {
        padding-top: 200px;
    }

    .mobileSwiper {
        display: block;
    }
}

@media only screen and (min-width: 1650px) {
    .imageWrapper {
        top: 30%;
    }
}

@media only screen and (min-width: 1750px) {
    .dark {
        padding-top: 350px;
    }
}

@media only screen and (min-width: 1920px) {
    .imageWrapper {
        top: 25%;
    }

    .dark {
        padding-top: 400px;
    }
}

@media only screen and (min-width: 2150px) {
    .dark {
        padding-top: 450px;
    }
}

@media only screen and (min-width: 2300px) {
    .dark {
        padding-top: 500px;
    }
}

@media only screen and (min-width: 2500px) {
    .dark {
        padding-top: 550px;
    }
}