.mobileShowcase {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100vw;
    justify-content: center;
}

.singleShowcase {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 49px;
    transition: transform 0.3s ease;
}

.centerShowcase {
    width: 17vw;
    z-index: 30;
    margin: 0 -2vw;
}

.centerShowcase:hover {
    transform: translateY(-10px);
}

.secondRowShowcase {
    width: 15vw;
    margin: 0 0vw;
    z-index: 15;
}

.secondRowShowcase:hover {
    transform: translateY(-10px);
}

.thirdRowShowcase {
    width: 14vw;
    margin: 0 -2vw;
    z-index: 10;
}

.thirdRowShowcase:hover {
    transform: translateY(-10px);
}

@media only screen and (max-width: 1025px) {
    .mobileShowcase {
        display: none;
    }
}