.itemsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
}

.body14 {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.bobaContainer {
    display: flex;
    gap: 4px;
    flex-direction: row;
    align-items: center;
}

.heading11 {
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: -0.01 * 11px;
    text-align: center;
}