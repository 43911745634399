.cardContainer {
    display: flex;
    background-color: rgb(49, 54, 60);
    border: 1px solid rgba(87, 91, 102, .5);
    border-radius: 24px;
    padding: 24px;
    min-width: 27vw;
    min-height: 200px;
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: flex-start;
}

.cardHeading {
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    min-height: 50px;
}

.cardText {
    color: #d9d9d9;
    font-family: "Inter", sans-serif;
    font-size: 14px;
}