.inputGroup {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */

}

.input {
    border: solid 1.5px #9e9e9e;
    border-radius: 1rem;
    background: none;
    padding: 1rem;
    font-size: 1rem;
    color: #f5f5f5;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);

    width: 80%;
}

.userLabel {
    position: absolute;
    left: 35px;
    color: #e8e8e8;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.inputHasContent,
.input:focus {
    outline: none;
    border: 1.5px solid #1a73e8;
}

.inputHasContent+.userLabel,
.input:focus~.userLabel {
    transform: translateY(-50%) scale(0.8);
    background-color: rgb(49, 54, 60);
    padding: 0 .2em;
    color: #2196f3;
}

.textareaResizable {
    resize: both;
    max-width: 120%;
    max-height: 400px;
}


@media only screen and (max-width: 1100px) {
    .userLabel {
        left: 30px;
    }
}

@media only screen and (min-width: 1600px) {
    .userLabel {
        left: 45px;
    }
}

@media only screen and (min-width: 2000px) {
    .userLabel {
        left: 50px;
    }
}

@media only screen and (min-width: 2200px) {
    .userLabel {
        left: 55px;
    }
}

@media only screen and (min-width: 2400px) {
    .userLabel {
        left: 60px;
    }
}

@media only screen and (max-width: 950px) {
    .userLabel {
        left: 25px;
    }
}

@media only screen and (max-width: 800px) {
    .userLabel {
        left: 20px;
    }
}

@media only screen and (max-width: 769px) {
    .input {
        width: 120%;
    }

    .userLabel {
        left: -20px;
    }
}