.snackbarContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    background-color: #333;
    border-radius: 10px;
    align-items: center;
    padding: 10px;
}

.backgroundSuccess{
    background-color: lightgreen;
}

.backgroundFailure{
    background-color: lightcoral;
}

.snackbarText{
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #FFF;
    font-weight: bold;
}

.closeIcon{
    font-size: 26px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
    transition: all 0.3s ease;
}

.closeIcon:hover{
    cursor: pointer;
    color: #FFF
}

@media only screen and (max-width: 768px){
    .snackbarContainer{
        height: 35px;
    }
}