.mobileView {
    /* width: 50vw; */
    width: 50vw;
    padding-bottom: 50px;
}

.mySwiper {
    width: auto;
}

.swiperSlide {
    font-size: 18px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

span .swiper-pagination-bullet .swiper-pagination-bullet-active {
    background: #FFFFFF !important;
}