/* Footer.module.css */
.footer {
    background-color: #202731;
    color: #fff;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.copyright {
    border-top: #d9d9d9 2px solid;
    background-color: #202731;
    color: white;
    display: flex;
    justify-content: center;
}

.logoWrapper {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-items: center;
    /* flex: 1/3; */
    width: 20%;
    /* gap: 32px */
}

.logo {
    width: auto;
    height: auto;
}

.microsoftImage {
    width: 25%;
    height: auto;
}

.infoWrapper {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 64px;
    /* width: 60%; */
}

.social {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.social a {
    color: white;
}

.social a:nth-child(1):hover {
    color: #FF6525;
    text-decoration: none;
    transition: color 0.15s ease;
}

.social a:nth-child(2):hover {
    color: #4848d5;
    text-decoration: none;
}


.contact,
.appInfo {
    text-align: left;
    font-family: "Poppins";
}

.contact p,
.appInfo p {
    margin: 5px 0;
}

@media (max-width: 770px) {
    .logoWrapper {
        flex-direction: column;
    }

    .footer {
        flex-direction: column;
        gap: 32px;
    }

    .infoWrapper {
        flex-direction: column;
        gap: 0px
    }

    .microsoftImage {
        width: 80%;
    }
}