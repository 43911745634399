.cardContainer {
    display: flex;
    background-color: rgb(49, 54, 60);
    border: 1px solid rgba(87, 91, 102, .5);
    border-radius: 24px;
    padding: 24px;
    min-width: 27vw;
    width: 75%;
    min-height: 200px;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 100;
}

.formContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.form {
    display: flex;
    width: 60%;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.actionButton {
    position: relative;
    padding: 5px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 10px;
    border: none;
}


.appleButton {
    color: white;
    background-color: #4848d5;
    /* box-shadow: 0 0 0 4px black, 0 0 0 8px white; */
}

.appleButton::before {
    content: '';
    /* Required */
    position: absolute;
    top: -5px;
    /* Distance from the button */
    left: -5px;
    /* Distance from the button */
    right: -5px;
    /* Distance from the button */
    bottom: -5px;
    /* Distance from the button */
    border: 1px solid #FFF;
    transition: all 0.3s ease;
    /* Border color and width */
    border-radius: 10px;
    /* If the button has rounded corners */
}

.appleButton:hover {
    background-color: white;
    color: #4848d5;
    cursor: pointer;
}

.appleButton:hover::before {
    border-color: white;
    /* Change border color on hover */
    top: -8px;
    /* Increase the space */
    left: -8px;
    right: -8px;
    bottom: -8px;
}

.contactButton {
    margin: 25px;
    padding: 0px 40px;
}

.cardText {
    font-size: 16px;
    font-family: "Inter", sans-serif;
}

.mailIcon {
    width: 40%;
}

@media only screen and (max-width: 769px) {
    .formContainer {
        flex-direction: column;
        align-items: center;

    }
}